<template>
  <div>
    <b-row>
      <b-col lg="8" class="content-d">
        <p class="content-slogan w-100 m-0">{{ $t('install') }}</p>
        <p>{{ $t('install-2') }}</p>
        <p>{{ $t('install-3') }} <a target="_blank" class="text-info" href="https://play.google.com/store/apps/details?id=com.nltbus&hl=vi&gl=US&pli=1">{{ $t('here') }}</a></p>
        <p>{{ $t('install-4') }} <a target="_blank" class="text-info" href="https://apps.apple.com/vn/app/nlt-smartbus/id6453477112">{{ $t('here') }}</a></p>
        <!-- <p>{{ $t('install-5') }}</p> -->
        <p class="content-slogan w-100 m-0">{{ $t('manual-guide') }}</p>
        <p><strong class="mr-2">{{ $t('step1') }}</strong>{{ $t('manual-guide-2') }}</p>
        <p>{{ $t('manual-guide-3') }}</p>
        <div class="text-center">
          <img src="/assets/image/guide/guide_14.jpg" height="512px">
        </div>
        <p>{{ $t('manual-guide-4') }}</p>
        <div class="text-center">
          <img src="/assets/image/guide/guide_26.jpg" height="512px">
        </div>
        <p>{{ $t('manual-guide-5') }}</p>
        <div class="text-center">
          <img src="/assets/image/guide/guide_16.jpg" height="512px">
        </div>
        <p>{{ $t('manual-guide-6') }}</p>
        <div class="text-center">
          <img src="/assets/image/guide/guide_17.png" height="512px">
        </div>
        <p><strong class="mr-2">{{ $t('step2') }}</strong>{{ $t('manual-guide-7') }}</p>
        <div class="text-center">
          <img src="/assets/image/guide/guide_18.jpg" height="512px">
        </div>
        <p><strong class="mr-2">{{ $t('step3') }}</strong>{{ $t('manual-guide-8') }}</p>
        <div class="text-center">
          <img src="/assets/image/guide/guide_19.png" height="512px">
        </div>
        <p>{{ $t('manual-guide-9') }}</p>
        <div class="text-center">
          <img src="/assets/image/guide/guide_6.jpg" height="512px">
        </div>
        <p><strong class="mr-2">{{ $t('step4') }}</strong>{{ $t('manual-guide-10') }}</p>
        <div class="text-center">
          <img src="/assets/image/guide/guide_1.png" height="512px">
        </div>
        <p>{{ $t('manual-guide-11') }}</p>
        <div class="text-center">
          <img src="/assets/image/guide/guide_20.jpg" height="512px">
        </div>
        <p>{{ $t('manual-guide-12') }}</p>
      </b-col>
      <b-col lg="4">
        <RightMenu />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RightMenu from "@/layout/RightMenu";
export default {
  components: {
    RightMenu,
  },
};
</script>

<style scoped>
.content-d p {
  font-size: 16px;
}
.content-d img{
  margin-bottom: 40px;
}
.separator {
  background: #e6e8ec;
  height: 1px;
}
.menu-content {
  color: var(--website-neutral-60, #18191a);
  /* Website & tablet/Menu */
  font-size: 14px;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  text-transform: uppercase;
}
.menu-title {
  color: var(--brand-color, #c71313);
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
}
.right-menu {
  border-radius: 24px;
  border: 1px solid var(--website-gray, #e6e8ec);
  background: var(--website-white, #fff);
  padding: 32px;
}
.font-64 {
  font-size: 64px;
}
.font-24 {
  font-size: 24px;
}
.title-block {
  font-size: 12px;
  font-weight: 300;
  color: #636466;
  width: 75%;
}
.title-secondary {
  font-size: 16px;
  font-weight: 300;
  color: #636466;
  width: 75%;
  margin-bottom: 40px !important;
}
.content-slogan {
  color: var(--website-brand-color, #c71313);
  /* Website & tablet/Header 1 */
  font-size: 48px !important;
  font-weight: 400;
  line-height: 60px; /* 125% */
  margin-bottom: 40px !important;
}
.content-card {
  border: 1px solid #e6e8ec;
  border-radius: 24px;
  overflow: hidden;
  transition: 500ms;
  cursor: pointer;
}
.content-card p {
  height: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.content-card:hover {
  box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
}
</style>
